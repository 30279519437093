import React, { useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useMutation } from "@apollo/client";
import { CREATE_SUBSCRIBER } from "../../utils/mutations.js";
import * as Yup from "yup";

export default function SubscribeForm() {
  const [createSubscriber] = useMutation(CREATE_SUBSCRIBER);
  const [successOpen, setSuccessOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(true);

  // const fileRef = useRef(null);

  const initialValues = {
    name: "",
    email: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    email: Yup
        .string()
        .email("Invalid email format")
        .required("Email is required"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      console.log(values.image);
      console.log("Submitting:", values);

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);

      try {
        await createSubscriber({
          variables: {
            name: values.name,
            email: values.email,
          },
        });
        resetForm();
        console.log("Form submitted successfully");
        showSuccessMessage();
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.error("Submission error:", err);
    }
    setSubmitting(false);
  };

  function showSuccessMessage() {
    setSuccessOpen((prev) => !prev);
    setFormOpen((prev) => !prev);
    setTimeout(() => {
      setSuccessOpen((prev) => !prev);
      setFormOpen((prev) => !prev);
    }, 5000);
  }

  return (
    <div className="bg-accent rounded-lg p-5 border border-accent">
      <h1 className="text-lg text-center text-white">SUBSCRIBE FOR UPDATES</h1>
      {formOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              {/* NAME */}
              <div className="form-control">
                <label className="label" htmlFor="name">
                  <span className="label-text text-md">NAME</span>
                </label>
                <Field
                  className="input input-bordered text-white"
                  type="text"
                  name="name"
                  placeholder="nAmE"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="error text-white"
                />
              </div>

              {/* EMAIL */}
              <div className="form-control">
                <label className="label" htmlFor="email">
                  <span className="label-text text-md">EMAIL</span>
                </label>
                <Field
                  className="input input-bordered text-white"
                  type="text"
                  name="email"
                  placeholder="EmAiL"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error text-white"
                />
              </div>

              <div className="form-control mt-6">
                <button
                  className="btn btn-lg btn-warning mx-auto"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {successOpen && (
        <p className="max-w-md my-6 text-lg">Successfully Subscribed</p>
      )}
    </div>
  );
}
