import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useQuery } from '@apollo/client';
import { PAY } from '../../utils/queries';
import CheckoutForm from './CheckoutForm';
import { useNavigate } from 'react-router-dom';
import { CREATE_TICKETS } from '../../utils/mutations';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

const stripePromise = loadStripe(
  'pk_live_51Pb7DZ2NwjZjFvS7q1iMJ0xruDkYj25NmBDhzDH6EmyfnaDN9i8rxZ0uTVf3P7KWAH6s6NTE8gi36mMeT4QPkTT300l6NiwCT4'
);

const LiabilityModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-y-auto'>
      <div className='bg-white p-6 rounded-lg shadow-lg max-w-lg w-full max-h-[90vh] overflow-y-auto'>
        <h2 className='text-2xl font-bold mb-4'>
          Liability Waiver and Release Agreement
        </h2>
        <div className='min-h-screen bg-gray-900 flex items-center justify-center p-8'>
          <div className='max-w-3xl w-full bg-gray-800 text-white p-8 rounded-lg shadow-lg'>
            <h1 className='text-3xl font-bold text-center mb-6 text-red-500'>
              Event Waiver & Release
            </h1>
            <p className='mb-4'>
              On behalf of ticket holder and any individual or entity associated
              (defined below), holder waives, releases, and agrees not to sue{' '}
              <span className='font-semibold'>Shellevate Lounge</span> or any of
              the related parties with respect to any claims, including wrongful
              death and/or negligence, that relate to any hazards, risks, or
              dangers, without any limitations, including any and all damages of
              any kind, that relate to the event in any way, such as but not
              limited to:
            </p>
            <ul className='list-disc list-inside pl-4 mb-4'>
              <li>
                (i) Holder's personal information, or presence around or within
                an event (including but not limited to any risks in parking
                areas or compliance with any security requirements or protocols
                applicable to said event)
              </li>
              <li>(ii) Holder's exposure to injury, sickness, or illness</li>
              <li>
                (iii) Any interaction between holder and any individual or
                entity associated with holder at said event
              </li>
              <li>
                (iv) Any act or exclusion on behalf of any of the parties in
                connection with upholding protocols, in the case of any
                negligence, action, or lack thereof
              </li>
            </ul>
            <p className='mb-4'>
              (v) Ticket holder agrees to indemnify and hold harmless the{' '}
              <span className='font-semibold'>Shellevate Lounge</span> from any
              claims brought by third parties arising out of the ticket holder's
              actions or participation in the event.
            </p>
            <p className='mb-4'>
              Holder further represents that holder and any individual or entity
              associated has reviewed and separately understood and agreed to
              this agreement.
            </p>
            <p className='mb-4'>
              <span className='font-semibold'>
                "Any individual or entity associated"
              </span>{' '}
              means holder's friends, associates, family members, minors for
              whom the holder is their parent or guardian, administrators,
              anyone attending the event, or from whom the holder has obtained
              said ticket, including but not limited to other persons acting or
              attempting to act on the holder's behalf.
            </p>
          </div>
        </div>
        <button
          onClick={onClose}
          className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
        >
          Close
        </button>
      </div>
    </div>
  );
};

const Pay = ({ event, tier, quantity, selectedDeliveryMethod }) => {
  const navigate = useNavigate();
  const [createTickets] = useMutation(CREATE_TICKETS);

  const createFreeTickets = async () => {
    const paymentConfirmation = uuidv4();

    const variables = {
      tier: Number(tier),
      quantity: Number(quantity),
      deliveryMethod: selectedDeliveryMethod,
      name,
      email,
      eventId: event._id,
      paymentConfirmation,
      promoId: '',
    };

    await createTickets({
      variables,
    });

    navigate('/paymentSuccess/' + paymentConfirmation);
  };

  const [paymentAmount, setPaymentAmount] = useState(
    event.tiers[tier - 1].price * quantity +
      event.ticketDeliveryOptions[selectedDeliveryMethod].price
  );
  const [ready, setReady] = useState('false');
  const [clientSecret, setClientSecret] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [foundPromo, setFoundPromo] = useState({
    _id: '',
    quantity: 0,
    code: '',
    discount: 0,
  });
  const [showPromoForm, setShowPromoForm] = useState(true);
  const [showCustomerForm, setShowCustomerForm] = useState(true);
  const [showPromoWrong, setShowPromoWrong] = useState(false);
  const [showPromoInfo, setShowPromoInfo] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [waiverAccepted, setWaiverAccepted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let taxes = paymentAmount * 0.0881;
  let fees = paymentAmount * 0.029 + 2.3;
  if (paymentAmount === 0) {
    taxes = 0;
    fees = 0;
  }
  const finalAmount = paymentAmount + taxes + fees;

  const handleChange = (event) => {
    setPromoCode(event.target.value);
  };

  const {
    loading: payLoading,
    data: payData,
    error: payError,
  } = useQuery(PAY, {
    variables: {
      amount: Math.round((paymentAmount + taxes + fees) * 100) / 100,
      description: `${event.date}, ${event.title}, ${name}, ${email} tickets`,
    },
    skip: ready === 'false',
    onCompleted: (data) => {
      console.log(data);
      setClientSecret(data.createCheckoutSession);
      setReady('false');
    },
  });

  let promo = {};
  const checkPromoCode = () => {
    promo = event.promos.find((p) => p.code === promoCode);
    console.log(promo);
    if (promo && promo.quantity !== 0) {
      setFoundPromo({
        _id: promo._id,
        code: promo.code,
        discount: promo.discount,
        quantity: promo.quantity,
      });

      setPaymentAmount(paymentAmount - promo.discount);
      setShowPromoWrong(false);
      setShowPromoInfo(true);
      setShowPromoForm(false);
    } else {
      setShowPromoWrong(true);
    }
  };

  const appearance = {
    theme: 'night',
    labels: 'floating',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div
      className='Pay flex justify-center items-center h-screen bg-cover bg-center'
      style={{ backgroundImage: `url('/path/to/earth-image.jpg')` }}
    >
      <div className='bg-gray-900 bg-opacity-80 p-8 mt-32 rounded-lg w-full md:w-3/4 lg:w-1/2 xl:w-1/3 border border-yellow-500 shadow-lg mt-30'>
        <h1 className='text-3xl text-center mb-6 text-white'>CHECKOUT</h1>
        <div className='flex flex-col space-y-4'>
          <div className='flex flex-col bg-white bg-opacity-90 p-4 rounded-lg shadow-md'>
            <div className='flex justify-between'>
              <p className='mb-2 text-gray-800'>Ticket Price:</p>
              <p className='mb-2 text-gray-800'>
                {' '}
                ${event.tiers[tier - 1].price.toFixed(2)}
              </p>
            </div>
            <div className='flex justify-between'>
              <p className='mb-2 text-gray-800'>Quantity:</p>
              <p className='mb-2 text-gray-800'> {quantity}</p>
            </div>
            <div className='flex justify-between'>
              <p className='mb-2 text-gray-800'>Delivery Price:</p>
              <p className='mb-2 text-gray-800'>
                $
                {event.ticketDeliveryOptions[
                  selectedDeliveryMethod
                ].price.toFixed(2)}
              </p>
            </div>
            <div className='flex justify-between border-b border-black'>
              <p className='mb-2 text-gray-800'>Subtotal:</p>
              <p className='mb-2 text-gray-800'>${paymentAmount.toFixed(2)}</p>
            </div>
            <div className='flex justify-between'>
              <p className='mb-2 text-gray-800'>Discount:</p>
              <p className='mb-2 text-gray-800'>
                -${foundPromo.discount.toFixed(2)}
              </p>
            </div>
            <div className='flex justify-between'>
              <p className='mb-2 text-gray-800'>Sales Tax:</p>
              <p className='mb-2 text-gray-800'>${taxes.toFixed(2)}</p>
            </div>
            <div className='flex justify-between  border-b border-black'>
              <p className='mb-2 text-gray-800'>Fees:</p>
              <p className='mb-2 text-gray-800'>${fees.toFixed(2)}</p>
            </div>
            <div className='flex justify-between'>
              <p className='mb-2 text-gray-800'>Order Total:</p>
              <p className='mb-2 text-gray-800'>
                ${(paymentAmount + taxes + fees).toFixed(2)}
              </p>
            </div>
            {showPromoForm ? (
              <div className='flex'>
                <input
                  type='text'
                  placeholder='Promo Code'
                  className='w-full rounded-md border-gray-300 focus:ring-blue-500 focus:border-blue-500 px-3'
                  value={promoCode}
                  onChange={handleChange}
                />
                <button
                  type='button'
                  className='ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
                  onClick={checkPromoCode}
                >
                  Apply
                </button>
              </div>
            ) : (
              <div className='flex justify-between'>
                <p className='mb-2 text-gray-800'>Promo Code:</p>
                <p className='mb-2 text-gray-800'>{foundPromo.code}</p>
              </div>
            )}
            {showPromoWrong && (
              <div className='text-red-500'>Invalid Promo Code</div>
            )}
            {showPromoInfo && (
              <div className='text-green-500'>
                {foundPromo.code} Applied Successfully
              </div>
            )}
          </div>
          {showCustomerForm && (
            <form className='flex flex-col space-y-4'>
              <div>
                <label
                  htmlFor='name'
                  className='block text-sm font-medium text-white'
                >
                  Name
                </label>
                <input
                  type='text'
                  id='name'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-white'
                >
                  Email
                </label>
                <input
                  type='email'
                  id='email'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {event.venue === 'Shellevate Lounge' ? (
                <div>
                  <input
                    type='checkbox'
                    id='waiver'
                    className='mr-2'
                    checked={waiverAccepted}
                    onChange={() => setWaiverAccepted(!waiverAccepted)}
                  />
                  <label htmlFor='waiver' className='text-white'>
                    I accept the{' '}
                    <button
                      type='button'
                      className='underline text-blue-500 hover:text-blue-600'
                      onClick={() => setIsModalOpen(true)}
                    >
                      liability waiver
                    </button>
                    .
                  </label>
                </div>
              ) : (
                <></>
              )}

              <button
                type='button'
                className='px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600'
                onClick={() => {
                  if (event.venue !== 'Shellevate Lounge') {
                    setWaiverAccepted(true);
                  }
                  if (name && email && waiverAccepted) {
                    if (paymentAmount !== 0) {
                      setShowCustomerForm(false);
                      setReady('true');
                    }
                    if (paymentAmount === 0) {
                      createFreeTickets();
                    }
                  }
                }}
                disabled={!name || !email}
              >
                Proceed to Checkout
              </button>
            </form>
          )}
          {!showCustomerForm && clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                amount={paymentAmount}
                taxes={Math.round(taxes * 100) / 100}
                fees={Math.round(fees * 100) / 100}
                orderTotal={paymentAmount + taxes + fees}
                name={name}
                email={email}
                tier={tier}
                quantity={quantity}
                deliveryMethod={selectedDeliveryMethod}
                event={event}
                promoId={foundPromo._id}
              />
            </Elements>
          )}
        </div>
      </div>
      <LiabilityModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Pay;
