import gql from 'graphql-tag';

const AUTHENTICATE = gql`
  mutation Authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      token
      user {
        _id
      }
    }
  }
`;

const CREATE_EVENT = gql`
  mutation CreateEvent(
    $date: String!
    $showTime: String!
    $title: String!
    $description: String!
    $image: String!
    $doorTime: String
    $venue: String
    $address: String
    $externalTicketLink: String
    $tiers: [TierInput]
    $promos: [PromoInput]
    $ticketDeliveryOptions: [TicketDeliveryOptionInput]
  ) {
    createEvent(
      date: $date
      showTime: $showTime
      title: $title
      description: $description
      image: $image
      doorTime: $doorTime
      venue: $venue
      address: $address
      externalTicketLink: $externalTicketLink
      tiers: $tiers
      promos: $promos
      ticketDeliveryOptions: $ticketDeliveryOptions
    ) {
      _id
    }
  }
`;

const CREATE_BLOG_POST = gql`
  mutation CreateBlogPost(
    $title: String!
    $author: String!
    $summary: String!
    $body: String!
    $image: String
  ) {
    createBlogPost(
      title: $title
      author: $author
      summary: $summary
      body: $body
      image: $image
    ) {
      _id
    }
  }
`;

const CREATE_SUBSCRIBER = gql`
  mutation CreateSubscriber($name: String!, $email: String!) {
    createSubscriber(name: $name, email: $email) {
      _id
    }
  }
`;

const CREATE_TICKETS = gql`
  mutation CreateTickets(
    $eventId: ID!
    $deliveryMethod: Int!
    $tier: Int!
    $quantity: Int!
    $promoId: ID
    $email: String!
    $name: String!
    $paymentConfirmation: String!
  ) {
    createTickets(
      eventId: $eventId
      deliveryMethod: $deliveryMethod
      tier: $tier
      quantity: $quantity
      promoId: $promoId
      email: $email
      name: $name
      paymentConfirmation: $paymentConfirmation
    ) {
      _id
    }
  }
`;

const DELETE_TICKETS = gql`
  mutation DeleteTickets($ids: [ID], $tier: Int) {
    deleteTickets(ids: $ids, tier: $tier)
  }
`;

const SEND_TICKET_EMAIL = gql`
  mutation Mutation($paymentConfirmation: String) {
    sendTicketEmail(paymentConfirmation: $paymentConfirmation)
  }
`;

const UPDATE_EVENT = gql`
  mutation UpdateEvent(
    $eventId: ID
    $date: String
    $doorTime: String
    $showTime: String
    $venue: String
    $address: String
    $title: String
    $description: String
    $image: String
    $externalTicketLink: String
    $tiers: [TierUpdateInput]
    $promos: [PromoUpdateInput]
    $ticketDeliveryOptions: [TicketDeliveryOptionUpdateInput]
  ) {
    updateEvent(
      eventId: $eventId
      date: $date
      doorTime: $doorTime
      showTime: $showTime
      venue: $venue
      address: $address
      title: $title
      description: $description
      image: $image
      externalTicketLink: $externalTicketLink
      tiers: $tiers
      promos: $promos
      ticketDeliveryOptions: $ticketDeliveryOptions
    ) {
      _id
    }
  }
`;

const CHECK_TICKETS = gql`
  mutation CheckTickets($barcode: String) {
    checkTickets(barcode: $barcode) {
      _id
      barcode
      deliveryMethod {
        method
      }
      entered
      email
      event {
        title
      }
      name
      paymentConfirmation
    }
  }
`;

const CREATE_INVASION_ARTIST = gql`
  mutation CreateInvasionArtist(
    $name: String!
    $bio: String!
    $image: String!
    $logo: String!
  ) {
    createInvasionArtist(name: $name, bio: $bio, image: $image, logo: $logo) {
      _id
    }
  }
`;

// const ARTIST_SUBMISSION = gql`

// `;

const ADD_DOOR_SALE = gql`
  mutation AddDoorSale(
    $eventId: ID!
    $method: String!
    $amount: Int!
    $quantity: Int!
  ) {
    addDoorSale(
      eventId: $eventId
      method: $method
      amount: $amount
      quantity: $quantity
    ) {
      _id
    }
  }
`;

const CREATE_PROMO_CODE = gql`
  mutation CreatePromoCode(
    $quantity: Int!
    $eventId: ID!
    $code: String!
    $amount: Int!
  ) {
    createPromoCode(
      quantity: $quantity
      eventId: $eventId
      code: $code
      amount: $amount
    ) {
      _id
    }
  }
`;

export {
  AUTHENTICATE,
  CREATE_EVENT,
  CREATE_BLOG_POST,
  CREATE_SUBSCRIBER,
  CREATE_TICKETS,
  DELETE_TICKETS,
  SEND_TICKET_EMAIL,
  UPDATE_EVENT,
  CHECK_TICKETS,
  CREATE_INVASION_ARTIST,
  // ARTIST_SUBMISSION,
  ADD_DOOR_SALE,
  CREATE_PROMO_CODE,
};
