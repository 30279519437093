import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect } from 'react';
import { React } from "react";

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
  let config = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  return config;
};

const Html5QrcodePlugin = (props) => {

  useEffect(() => {
    // when component mounts
    const config = createConfig(props);
    const verbose = props.verbose === true;
    // Suceess callback is required.
    if (!(props.qrCodeSuccessCallback)) {
      throw "qrCodeSuccessCallback is required callback.";
    }
    const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
    if(props.isScanning) {
      html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);
    }
    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner.clear().then(_ => {
        // the UI should be cleared here      
      }).catch(error => {
        // Could not stop scanning for reasons specified in `error`.
        // This conditions should ideally not happen.
      });
    };
  }, []);

  return (
      <div id={qrcodeRegionId} />
  );
};

export default Html5QrcodePlugin;