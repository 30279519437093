import React, { useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useMutation } from "@apollo/client";
import { CREATE_BLOG_POST } from "../../utils/mutations.js";
import * as Yup from "yup";
import FileUpload from "./FileUpload.js";

export default function CreateBlogPost() {
  const [createBlogPost] = useMutation(CREATE_BLOG_POST);
  const [successOpen, setSuccessOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(true);

  // const fileRef = useRef(null);

  const initialValues = {
    title: "",
    summary: "",
    body: "",
    image: "",
    author: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    summary: Yup.string().required("This field is required"),
    body: Yup.string().required("This field is required"),
    image: Yup.string(),
    author: Yup.string(),
    // image: Yup.mixed()
    //   .test("is-file-too-big", "File exceeds 10MB", (value) => {
    //     let valid = true;
    //     if (value) {
    //       const fileArr = Array.from(value);
    //       fileArr.forEach((file) => {
    //         const size = file.size / 1024 / 1024;
    //         if (size > 10) {
    //           valid = false;
    //         }
    //       });
    //     }
    //     return valid;
    //   })
    //   .test(
    //     "is-file-of-correct-type",
    //     "File is not of supported type",
    //     (value) => {
    //       let valid = true;
    //       if (value) {
    //         const fileArr = Array.from(value);
    //         fileArr.forEach((file) => {
    //           const type = file.type.split("/")[1];
    //           const validTypes = ["pdf", "jpeg", "png", "jpg", "gif"];
    //           if (!validTypes.includes(type)) {
    //             valid = false;
    //           }
    //         });
    //       }
    //       return valid;
    //     }
    //   ),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      console.log(values.image);
      console.log("Submitting:", values);

      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("author", values.author);
      formData.append("summary", values.summary);
      formData.append("body", values.body);
      formData.append("image", values.image);

      try {
        console.log(values.image);
        await createBlogPost({
          variables: {
            title: values.title,
            author: values.author,
            summary: values.summary,
            body: values.body,
            image: values.image,
          },
        });
        resetForm();
        console.log("Form submitted successfully");
        showSuccessMessage();
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.error("Submission error:", err);
    }
    setSubmitting(false);
  };

  function showSuccessMessage() {
    setSuccessOpen((prev) => !prev);
    setFormOpen((prev) => !prev);
    setTimeout(() => {
      setSuccessOpen((prev) => !prev);
      setFormOpen((prev) => !prev);
    }, 5000);
  }

  return (
    <div className="bg-accent p-10 rounded-lg m-6 border border-accent">
      <h1 className="text-xl text-center">NEW BLOG POST</h1>
      {formOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              {/* TITLE */}
              <div className="form-control">
                <label className="label" htmlFor="title">
                  <span className="label-text text-xl">Event Title</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="title"
                />
                <ErrorMessage name="title" component="div" className="error" />
              </div>

              {/* AUTHOR */}
              <div className="form-control">
                <label className="label" htmlFor="author">
                  <span className="label-text text-xl">Author</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="author"
                />
                <ErrorMessage name="author" component="div" className="error" />
              </div>
              {/* SUMMARY */}
              <div className="form-control">
                <label className="label" htmlFor="summary">
                  <span className="label-text text-xl">Summary</span>
                </label>
                <Field
                  className="input input-bordered"
                  as="textarea"
                  name="summary"
                />
                <ErrorMessage
                  name="summary"
                  component="div"
                  className="error"
                />
              </div>
              {/* BODY */}
              <div className="form-control">
                <label className="label" htmlFor="body">
                  <span className="label-text text-xl">Body</span>
                </label>
                <Field
                  className="input input-bordered"
                  as="textarea"
                  name="body"
                />
                <ErrorMessage name="body" component="div" className="error" />
              </div>
              {/* IMAGE */}
              <div className="form-control">
                <label className="label" htmlFor="image">
                  <span className="label-text text-xl">Image URL</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="image"
                />
                <ErrorMessage name="image" component="div" className="error" />
              </div>
              {/* <FileUpload name="image" fileRef={fileRef} /> */}
              <div className="form-control mt-6">
                <button
                  className="btn btn-lg btn-secondary mx-auto"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {successOpen && (
        <p className="max-w-md my-6 text-lg">Blog Post Successfully Created</p>
      )}
    </div>
  );
}
