import { React } from "react";
import { Link, useParams } from "react-router-dom";
import background from "./images/home-bg.jpg";
import { useQuery } from "@apollo/client";
import { QUERY_BLOG_POST_BY_ID } from "../utils/queries";

const BlogPost = () => {
  const { postId } = useParams();
  const { loading, data, error, refetch } = useQuery(
    QUERY_BLOG_POST_BY_ID,
    { variables: { postId: postId } },
    {
      onCompleted: (data) => console.log("Query completed:", data),
      onError: (error) => console.error("Query error:", error),
    }
  );

  if (loading) {
    return <h2>...loading</h2>;
  }

  if (error) {
    return <h2>Error: {error.message}</h2>;
  }

  if (!loading) {
    console.log(data);
    const blogPost = data.blogPostById;

    const formatDate = (timestamp) => {
      const date = new Date(parseInt(timestamp));
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month}.${day}.${year}`;
    };

    return (
      <>
        <div style={{ backgroundImage: `url(${background})` }}>
          <div className="flex flex-col ">
            {blogPost.image ? <img src={blogPost.image}></img> : <></>}
            <div className="flex justify-between">
              <div className="flex flex-col">
                <h1 className="text-white text-3xl m-7 mb-3">
                  {blogPost.title}
                </h1>
                <h2 className="text-white text-lg ml-7 mb-4">
                  Author: {blogPost.author}
                </h2>
              </div>
              <h2 className="text-white text-2xl m-7">
                {formatDate(blogPost.createdAt)}
              </h2>
            </div>
            <p className="text-white text-lg m-6">{blogPost.body}</p>
          </div>
        </div>
      </>
    );
  }
};
export default BlogPost;
