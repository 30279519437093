import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_FUTURE_EVENTS } from "../utils/queries";
import logo from "./images/minimalist-logo-bonw.png";

const Events = () => {
  const { loading, data, error, refetch } = useQuery(
    QUERY_FUTURE_EVENTS,
    { variables: {} },
    {
      onCompleted: (data) => console.log("Query completed:", data),
      onError: (error) => console.error("Query error:", error),
    }
  );

  if (loading) {
    return <h2>...loading</h2>;
  }

  if (error) {
    return <h2>Error: {error.message}</h2>;
  }

  if (!loading) {
    const events = data?.futureEvents ? [...data.futureEvents] : [];

    // Sort the events by date
    events.sort((a, b) => new Date(a.date) - new Date(b.date));

    return (
      <>
        {loading ? (
          <h2>"Loading..."</h2>
        ) : (
          <div
            id="eventsContainer"
            className="text-white flex justify-center flex-wrap"
          >
            <h1 className="text-3xl text-center mt-4 underline">
              UPCOMING EVENTS
            </h1>
            {events.map((event) => (
              <div
                key={event._id}
                className="flex justify-evenly flex-col md:flex-row lg:flex-row w-full border-b p-5"
              >
                {event.imgUrl ? (
                  <img
                    src={event.imgUrl}
                    className="bg-white w-full md:w-1/2 lg:w-1/2"
                  ></img>
                ) : (
                  <img
                    src={logo}
                    className="bg-white w-full md:w-1/2 lg:w-1/2"
                  ></img>
                )}

                <div className="flex flex-col w-full md:w-1/3 lg:w-1/2 p-5">
                  <h2 className="text-2xl mt-3">
                    {event.date.slice(5, 7) +
                      "." +
                      event.date.slice(8, 10) +
                      "." +
                      event.date.slice(0, 4)}
                  </h2>
                  <h2 className="text-3xl mb-3">{event.title}</h2>
                  <h3 className="text-md">Doors: {event.doorTime}</h3>
                  <h3 className="text-md mb-3">Show: {event.showTime}</h3>
                  <h3 className="text-md">{event.venue}</h3>
                  <h3 className="text-sm">{event.address}</h3>
                  <h3 className="text-lg mt-3">{event.description}</h3>
                  <div className="flex justify-around">
                    {event.title === "Invasion" ? (
                      <div className="flex justify-center">
                        <Link
                          to="/Invasion"
                          className="btn btn-warning text-black m-3"
                        >
                          MORE INFO
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                    {event.tiers.length ? (
                      <div className="flex justify-center">
                        <Link
                          to={"/events/" + event._id}
                          className="btn btn-white text-black m-3"
                        >
                          BUY TICKETS
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                    {event.externalTicketLink !== "" ? (
                      <div className="flex justify-center">
                        <Link
                          to={event.externalTicketLink}
                          className="btn btn-white text-black m-3"
                        >
                          BUY TICKETS
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                    {!event.tiers && event.externalTicketLink === null ? (
                      <h4>FREE SHOW</h4>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
};
export default Events;
