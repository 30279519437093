import React from "react";
import { Link } from "react-router-dom";
import background from "./images/home-bg.jpg";
import { useQuery } from "@apollo/client";
import { QUERY_BLOG_POSTS } from "../utils/queries";

const Blog = () => {
  const { loading, data, error } = useQuery(QUERY_BLOG_POSTS);

  if (loading) {
    return <h2>...loading</h2>;
  }

  if (error) {
    return <h2>Error: {error.message}</h2>;
  }

  const blogPosts = data?.blogPosts ? [...data.blogPosts] : [];

  const formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}.${day}.${year}`;
  };

  return (
      <div
          style={{ backgroundImage: `url(${background})` }}
          className="min-h-screen bg-cover bg-center relative z-10"
      >
        <div className="text-black flex flex-col justify-center items-center align-middle">
          <div className="bg-white opacity-90 my-5 h-16 w-1/3 text-center text-3xl flex flex-col justify-center">
            <h2>Blog</h2>
          </div>
        </div>
        <div className="flex flex-col items-center">
          {blogPosts.map((p) => (
              <div key={p._id} className="border-y border-white w-5/6 p-7">
                <div className="flex justify-center">
                  <img className="w-full md:w-1/2 lg:w-1/3" src={p.image} alt={p.title} />
                </div>
                <div className="flex flex-col sm:flex-row sm:justify-between mb-5 text-left sm:text-right">
                  <Link
                      to={"/blog/" + p._id}
                      className="text-2xl hover:underline sm:text-left"
                  >
                    {p.title}
                  </Link>
                  <h3 className="text-xl sm:ml-4">{formatDate(p.createdAt)}</h3>
                </div>
                <p>{p.summary}</p>
              </div>
          ))}
        </div>
      </div>
  );
};

export default Blog;
