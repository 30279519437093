import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import background from "../images/home-bg.jpg";
import SelectDelivery from "./SelectDelivery";
import { useQuery } from "@apollo/client";
import { QUERY_EVENT_BY_ID } from "../../utils/queries";
import Pay from "./Pay";

const BuyTickets = () => {
  const navigate = useNavigate();
  const [checkoutPage, setCheckoutPage] = useState(2);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState("");
  const { eventId, tier, quantity } = useParams();
  const { loading, data, error } = useQuery(QUERY_EVENT_BY_ID, {
    variables: { eventId: eventId },
    // onCompleted: (data) => console.log("Query completed:", data),
    // onError: (error) => console.error("Query error:", error),
  });

  // Timer state
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds

  // Function to start timer
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clear interval on unmount to prevent memory leaks
    return () => clearInterval(timer);
  }, []);

  // Redirect to event details page when time runs out
  useEffect(() => {
    if (timeLeft === 0) {
      navigate(`/events/${eventId}`);
    }
  }, [timeLeft, navigate, eventId]);

  // Wrap pageNames in useMemo
  const pageNames = useMemo(
    () => [
      "Step 1|Select Tickets",
      "Step 2|Delivery",
      "Step 3|Pay",
      "Step 4|Confirmation",
    ],
    []
  );

  // Memoize non-timer parts of the component to prevent unnecessary re-renders
  const renderSteps = useMemo(() => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    return (
      <div className="flex justify-between mb-20">
        <div id="checkoutSteps" className="flex flex-wrap">
          {pageNames.map((page, index) => {
            let pageClass = "text-white p-3";
            if (index + 1 < checkoutPage) {
              pageClass = "text-gray-500 p-3";
            }
            if (index === checkoutPage - 1) {
              pageClass = "text-warning p-3";
            }
            return (
              <p key={"page" + index} className={pageClass}>
                {page}
              </p>
            );
          })}
        </div>
        <div className="flex flex-col justify-center">
          <div className="bg-white text-black bg-opacity-80 p-2 shadow-lg">
            Tickets expire in: {minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </div>
        </div>
      </div>
    );
  }, [checkoutPage, timeLeft, pageNames]);

  if (loading) {
    return <p>LOADING ....</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const event = data?.eventById;

  const handleNext = () => {
    if (checkoutPage === 2 && selectedDeliveryMethod === "") {
      return;
    }
    setCheckoutPage(checkoutPage + 1);
  };

  const handleBack = () => {
    if (checkoutPage === 2) {
      navigate(`/events/${eventId}`);
    }
    setCheckoutPage(checkoutPage - 1);
  };

  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className=" bg-cover bg-center relative"
    >
      {renderSteps}
      {checkoutPage === 2 && (
        <SelectDelivery
          event={event}
          selectedDeliveryMethod={selectedDeliveryMethod}
          setSelectedDeliveryMethod={setSelectedDeliveryMethod}
        />
      )}
      {checkoutPage === 3 && (
        <Pay
          event={event}
          tier={tier}
          quantity={quantity}
          selectedDeliveryMethod={selectedDeliveryMethod}
        />
      )}

      <div className="flex justify-between p-10 mt-48 md:mt-32 lg:mt-24">
        <button
          className="btn btn-white tracking-widest border"
          onClick={handleBack}
        >
          BACK
        </button>
        {checkoutPage !== 3 ? (
          <button
            className="btn btn-white tracking-widest"
            onClick={handleNext}
          >
            NEXT
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default BuyTickets;
