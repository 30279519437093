import React from "react";
import { Link } from "react-router-dom";
import invasion from "../images/invasion_poster.jpg";
import invasionBG from "../images/invasion_poster.jpg";
import InvasionNav from "./InvasionNav";
//import { useQuery } from '@apollo/client';

const Invasion = () => {
  return (
    <>
      <div>
        <InvasionNav />
        <div className="flex flex-col align-center bg-warning">
          <img src={invasion} className="shadow-lg"></img>
        </div>
      </div>
    </>
  );
};

export default Invasion;
