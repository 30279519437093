import React, { useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useMutation } from "@apollo/client";
import { AUTHENTICATE } from "../../utils/mutations.js";
import Auth from "../../utils/auth.js";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

export default function LoginForm() {
  const navigate = useNavigate();
  const [authenticate] = useMutation(AUTHENTICATE);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("This field is required"),
  });

  const handleSubmit = async (values) => {
    try {
      const { data } = await authenticate({
        variables: {
          email: values.email,
          password: values.password,
        },
      });
      // console.log(data);
      // verify token
      Auth.login(data.authenticate.token);

      navigate("/dashboard");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="bg-accent rounded-lg p-5 border border-accent">
      <h1 className="text-lg text-center text-white">Sign In</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            {/* EMAIL */}
            <div className="form-control">
              <label className="label" htmlFor="email">
                <span className="label-text text-md">EMAIL</span>
              </label>

              <Field
                className="input input-bordered text-white"
                type="text"
                name="email"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="error text-white"
              />
            </div>
            {/* PASSWORD */}
            <div className="form-control">
              <label className="label" htmlFor="password">
                <span className="label-text text-md">PASSWORD</span>
              </label>
              <Field
                className="input input-bordered text-white"
                type="password"
                name="password"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="error text-white"
              />
            </div>

            <div className="form-control mt-6">
              <button
                className="btn btn-lg btn-warning mx-auto"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
