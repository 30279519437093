import React, { useState, useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useMutation } from '@apollo/client';
import { CREATE_PROMO_CODE } from '../../utils/mutations';
import Auth from '../../utils/auth.js';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

export default function CreatePromoCodeForm({ eventId }) {
  const navigate = useNavigate();
  const [createPromoCode] = useMutation(CREATE_PROMO_CODE);

  const initialValues = {
    code: '',
    amount: '',
    quantity: '',
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required('This field is required'),
    code: Yup.string().required('This field is required'),
    quantity: Yup.number().required('This field is required'),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const { data } = await createPromoCode({
        variables: {
          amount: values.amount,
          code: values.code,
          quantity: values.quantity,
          eventId: eventId,
        },
      });
      resetForm();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className='bg-accent rounded-lg p-5 border border-accent'>
      <h1 className='text-lg text-center text-white'>New Promo Code</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            {/* METHOD */}
            <div className='form-control'>
              <label className='label' htmlFor='code'>
                <span className='label-text text-md'>Code</span>
              </label>
              <Field
                className='input input-bordered text-white'
                type='text'
                name='code'
              />
              <ErrorMessage
                name='code'
                component='div'
                className='error text-white'
              />
            </div>
            {/* AMOUNT */}
            <div className='form-control'>
              <label className='label' htmlFor='amount'>
                <span className='label-text text-md'>Discount Integer</span>
              </label>
              <Field
                className='input input-bordered text-white'
                type='number'
                name='amount'
              />
              <ErrorMessage
                name='amount'
                component='div'
                className='error text-white'
              />
            </div>
            {/* QUANTITY */}
            <div className='form-control'>
              <label className='label' htmlFor='quantity'>
                <span className='label-text text-md'>Quantity</span>
              </label>
              <Field
                className='input input-bordered text-white'
                type='number'
                name='quantity'
              />
              <ErrorMessage
                name='quantity'
                component='div'
                className='error text-white'
              />
            </div>

            <div className='form-control mt-6'>
              <button
                className='btn btn-lg btn-warning mx-auto'
                type='submit'
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
