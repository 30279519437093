import React, { useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useMutation } from "@apollo/client";
import { CREATE_EVENT } from "../../utils/mutations.js";
import * as Yup from "yup";
import FileUpload from "./FileUpload.js";
import { method } from "lodash";

export default function CreateEventForm() {
  const [createEvent] = useMutation(CREATE_EVENT);
  const [successOpen, setSuccessOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(true);

  // const fileRef = useRef(null);

  const initialValues = {
    date: "",
    doorTime: "",
    showTime: "",
    venue: "",
    address: "",
    title: "",
    description: "",
    image: "",
    externalTicketLink: "",
    tiers: [],
    promos: [],
    deliveryOptions: [
      { method: "", price: 0, recommended: false },
      { method: "", price: 0, recommended: false },
      { method: "", price: 0, recommended: false },
    ],
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string().required("This field is required"),
    venue: Yup.string(),
    address: Yup.string(),
    title: Yup.string().required("This field is required"),
    description: Yup.string(),
    image: Yup.string().required("This field is required"),
    externalTicketLink: Yup.string(),
    // image: Yup.mixed()
    //   .test("is-file-too-big", "File exceeds 10MB", (value) => {
    //     let valid = true;
    //     if (value) {
    //       const fileArr = Array.from(value);
    //       fileArr.forEach((file) => {
    //         const size = file.size / 1024 / 1024;
    //         if (size > 10) {
    //           valid = false;
    //         }
    //       });
    //     }
    //     return valid;
    //   })
    //   .test(
    //     "is-file-of-correct-type",
    //     "File is not of supported type",
    //     (value) => {
    //       let valid = true;
    //       if (value) {
    //         const fileArr = Array.from(value);
    //         fileArr.forEach((file) => {
    //           const type = file.type.split("/")[1];
    //           const validTypes = ["pdf", "jpeg", "png", "jpg", "gif"];
    //           if (!validTypes.includes(type)) {
    //             valid = false;
    //           }
    //         });
    //       }
    //       return valid;
    //     }
    //   ),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      console.log(values.image);
      console.log("Submitting:", values);

      // Filter through to make sure to only take values where there was input.
      const filteredTiers = values.tiers.filter(
        (tier) => tier.level !== "" || tier.price !== "" || tier.quantity !== ""
      );

      //restructure the values into numbers
      const processedTiers = filteredTiers.map((tier) => ({
        ...tier,
        price: Number(tier.price),
        quantity: Number(tier.quantity),
      }));

      // Filter promos
      const filteredPromos = values.promos.filter(
        (promo) => promo.code !== "" && promo.discount !== ""
      );

      const processedPromos = filteredPromos.map((promo) => ({
        ...promo,
        discount: Number(promo.discount),
        quantity: Number(promo.quantity),
      }));

      //filter delivery options
      const filteredDeliveryOptions = values.deliveryOptions.filter(
        (o) => o.method !== "" && o.price !== ""
      );

      filteredDeliveryOptions.map((o) => {
        if (o.method === "mobile") {
          o.description =
            "A link to access your tickets will be sent to your email after completing this purchase.";
        } else if (o.method === "will call") {
          o.description =
            "You may pick up your tickets from will call when you arrive at the Venue.  Please be sure to have your confirmation email and ID easily accessible when picking up your tickets.";
        } else if (o.method === "mail") {
          o.description =
            "Your tickets will be sent to you via USPS First Class Mail to the mailing address provided during checkout.  Tickets will begin shipment 6 weeks before show date.  Please allow standard shipping times of 1-5 business days. ";
        }
      });

      const processedDeliveryOptions = filteredDeliveryOptions.map(
        (option) => ({
          ...option,
          price: Number(option.price),
        })
      );
      console.log(processedDeliveryOptions);

      const formData = new FormData();
      formData.append("date", values.date);
      formData.append("doorTime", values.doorTime);
      formData.append("showTime", values.showTime);
      formData.append("venue", values.venue);
      formData.append("address", values.address);
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("tiers", JSON.stringify(processedTiers));
      formData.append("promos", JSON.stringify(processedPromos));
      formData.append(
        "ticketDeliveryOptions",
        JSON.stringify(processedDeliveryOptions)
      );
      formData.append("image", values.image);
      formData.append("externalTicketLink", values.externalTicketLink);

      console.log(formData);
      try {
        await createEvent({
          variables: {
            date: values.date,
            doorTime: values.doorTime,
            showTime: values.showTime,
            venue: values.venue,
            address: values.address,
            title: values.title,
            description: values.description,
            tiers: processedTiers,
            promos: processedPromos,
            image: values.image,
            externalTicketLink: values.externalTicketLink,
            ticketDeliveryOptions: processedDeliveryOptions,
          },
        });
        resetForm();
        console.log("Form submitted successfully");
        showSuccessMessage();
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.error("Submission error:", err);
    }
    setSubmitting(false);
  };

  function showSuccessMessage() {
    setSuccessOpen((prev) => !prev);
    setFormOpen((prev) => !prev);
    setTimeout(() => {
      setSuccessOpen((prev) => !prev);
      setFormOpen((prev) => !prev);
    }, 5000);
  }

  return (
    <div className="bg-accent p-10 rounded-lg m-6 border border-accent">
      <h1 className="text-xl text-center">NEW EVENT</h1>
      {formOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              {/* TITLE */}
              <div className="form-control">
                <label className="label" htmlFor="title">
                  <span className="label-text text-xl">Event Title</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="title"
                />
                <ErrorMessage name="title" component="div" className="error" />
              </div>
              {/* DATE */}
              <div className="form-control">
                <label className="label" htmlFor="date">
                  <span className="label-text text-xl">Event Date</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="date"
                  name="date"
                />
                <ErrorMessage name="date" component="div" className="error" />
              </div>
              {/* DOOR TIME */}
              <div className="form-control">
                <label className="label" htmlFor="doorTime">
                  <span className="label-text text-xl">Door Time</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="doorTime"
                />
                <ErrorMessage
                  name="doorTime"
                  component="div"
                  className="error"
                />
              </div>
              {/* SHOW TIME */}
              <div className="form-control">
                <label className="label" htmlFor="showTime">
                  <span className="label-text text-xl">Show Time</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="showTime"
                />
                <ErrorMessage
                  name="showTime"
                  component="div"
                  className="error"
                />
              </div>
              {/* VENUE */}
              <div className="form-control">
                <label className="label" htmlFor="venue">
                  <span className="label-text text-xl">Venue</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="venue"
                />
                <ErrorMessage name="venue" component="div" className="error" />
              </div>
              {/* ADDRESS */}
              <div className="form-control">
                <label className="label" htmlFor="address">
                  <span className="label-text text-xl">Address</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="address"
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error"
                />
              </div>
              {/* DESCRIPTION */}
              <div className="form-control">
                <label className="label" htmlFor="description">
                  <span className="label-text text-xl">Description</span>
                </label>
                <Field
                  className="input input-bordered"
                  as="textarea"
                  name="description"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>
              {/* Ticket Tiers */}
              <h2 className="text-3xl text-center mt-3">Ticket Tiers</h2>
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <div key={index} className="flex justify-between">
                  <h3 className="text-xl">{index + 1}</h3>
                  <div className="form-control">
                    <label className="label" htmlFor={`tiers[${index}].level`}>
                      <span className="label-text text-xl">Name</span>
                    </label>
                    <Field
                      className="input input-bordered"
                      type="text"
                      name={`tiers[${index}].level`}
                    />
                    <ErrorMessage
                      name={`tiers[${index}].level`}
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-control">
                    <label className="label" htmlFor={`tiers[${index}].price`}>
                      <span className="label-text text-xl">Price</span>
                    </label>
                    <Field
                      className="input input-bordered"
                      type="text"
                      name={`tiers[${index}].price`}
                    />
                    <ErrorMessage
                      name={`tiers[${index}].price`}
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-control">
                    <label
                      className="label"
                      htmlFor={`tiers[${index}].quantity`}
                    >
                      <span className="label-text text-xl">Quantity</span>
                    </label>
                    <Field
                      className="input input-bordered"
                      type="text"
                      name={`tiers[${index}].quantity`}
                    />
                    <ErrorMessage
                      name={`tiers[${index}].quantity`}
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              ))}
              {/* Promo Codes */}
              <h2 className="text-3xl text-center mt-3">Promo Codes</h2>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                <div key={index} className="flex justify-between">
                  <h3 className="text-xl">{index + 1}</h3>
                  <div className="form-control">
                    <label className="label" htmlFor={`promos[${index}].code`}>
                      <span className="label-text text-xl">Code</span>
                    </label>
                    <Field
                      className="input input-bordered"
                      type="text"
                      name={`promos[${index}].code`}
                    />
                    <ErrorMessage
                      name={`promos[${index}].code`}
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-control">
                    <label
                      className="label"
                      htmlFor={`promos[${index}].discount`}
                    >
                      <span className="label-text text-xl">Discount</span>
                    </label>
                    <Field
                      className="input input-bordered"
                      type="text"
                      name={`promos[${index}].discount`}
                    />
                    <ErrorMessage
                      name={`promos[${index}].discount`}
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-control">
                    <label
                      className="label"
                      htmlFor={`promos[${index}].quantity`}
                    >
                      <span className="label-text text-xl">Quantity</span>
                    </label>
                    <Field
                      className="input input-bordered"
                      type="text"
                      name={`promos[${index}].quantity`}
                    />
                    <ErrorMessage
                      name={`promos[${index}].quantity`}
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              ))}
              {/* Delivery options */}
              <h2 className="text-3xl text-center mt-3">Delivery Options</h2>
              {initialValues.deliveryOptions.map((option, index) => (
                <div key={index} className="flex justify-between">
                  <h3 className="text-xl">{`Option ${index + 1}`}</h3>
                  <div className="form-control">
                    <label
                      className="label"
                      htmlFor={`deliveryOptions[${index}].method`}
                    >
                      <span className="label-text text-xl">Method</span>
                    </label>
                    <Field
                      className="input input-bordered"
                      as="select"
                      name={`deliveryOptions[${index}].method`}
                    >
                      <option value="">Select a method</option>
                      <option value="mobile">Mobile</option>
                      <option value="will call">Will Call</option>
                      <option value="mail">Mail</option>
                    </Field>
                    <ErrorMessage
                      name={`deliveryOptions[${index}].method`}
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-control">
                    <label
                      className="label"
                      htmlFor={`deliveryOptions[${index}].price`}
                    >
                      <span className="label-text text-xl">Price</span>
                    </label>
                    <Field
                      className="input input-bordered"
                      type="text"
                      name={`deliveryOptions[${index}].price`}
                    ></Field>
                    <ErrorMessage
                      name={`deliveryOptions[${index}].price`}
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-control flex items-center">
                    <label
                      className="label"
                      htmlFor={`deliveryOptions[${index}].recommended`}
                    >
                      <span className="label-text text-xl">Recommended</span>
                    </label>
                    <Field
                      className="checkbox"
                      type="checkbox"
                      name={`deliveryOptions[${index}].recommended`}
                    />
                    <ErrorMessage
                      name={`deliveryOptions[${index}].recommended`}
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              ))}
              {/* IMAGE */}
              <div className="form-control">
                <label className="label" htmlFor="image">
                  <span className="label-text text-xl">Image URL</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="image"
                />
                <ErrorMessage name="image" component="div" className="error" />
              </div>
              {/* IMAGE */}
              <div className="form-control">
                <label className="label" htmlFor="externalTicketLink">
                  <span className="label-text text-xl">
                    External Ticket Link URL
                  </span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="externalTicketLink"
                />
                <ErrorMessage
                  name="externalTicketLink"
                  component="div"
                  className="error"
                />
              </div>
              {/* <FileUpload name="image" fileRef={fileRef} /> */}
              <div className="form-control mt-6">
                <button
                  className="btn btn-lg btn-secondary mx-auto"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {successOpen && (
        <p className="max-w-md my-6 text-lg">Event Successfully Created</p>
      )}
    </div>
  );
}
