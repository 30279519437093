import React, { useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useMutation } from "@apollo/client";
import { CREATE_INVASION_ARTIST } from "../../utils/mutations.js";
import * as Yup from "yup";
import FileUpload from "./FileUpload.js";

export default function CreateInvasionArtistForm() {
  const [createInvasionArtist] = useMutation(CREATE_INVASION_ARTIST);
  const [successOpen, setSuccessOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(true);

  // const fileRef = useRef(null);

  const initialValues = {
    name: "",
    bio: "",
    logo: "",
    image: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    bio: Yup.string().required("This field is required"),
    logo: Yup.string().required("This field is required"),
    image: Yup.string(),
    // image: Yup.mixed()
    //   .test("is-file-too-big", "File exceeds 10MB", (value) => {
    //     let valid = true;
    //     if (value) {
    //       const fileArr = Array.from(value);
    //       fileArr.forEach((file) => {
    //         const size = file.size / 1024 / 1024;
    //         if (size > 10) {
    //           valid = false;
    //         }
    //       });
    //     }
    //     return valid;
    //   })
    //   .test(
    //     "is-file-of-correct-type",
    //     "File is not of supported type",
    //     (value) => {
    //       let valid = true;
    //       if (value) {
    //         const fileArr = Array.from(value);
    //         fileArr.forEach((file) => {
    //           const type = file.type.split("/")[1];
    //           const validTypes = ["pdf", "jpeg", "png", "jpg", "gif"];
    //           if (!validTypes.includes(type)) {
    //             valid = false;
    //           }
    //         });
    //       }
    //       return valid;
    //     }
    //   ),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      console.log(values.image);
      console.log("Submitting:", values);

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("bio", values.bio);
      formData.append("logo", values.logo);
      formData.append("image", values.image);

      try {
        console.log(values.image);
        await createInvasionArtist({
          variables: {
            name: values.name,
            bio: values.bio,
            logo: values.logo,
            image: values.image,
          },
        });
        resetForm();
        console.log("Form submitted successfully");
        showSuccessMessage();
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.error("Submission error:", err);
    }
    setSubmitting(false);
  };

  function showSuccessMessage() {
    setSuccessOpen((prev) => !prev);
    setFormOpen((prev) => !prev);
    setTimeout(() => {
      setSuccessOpen((prev) => !prev);
      setFormOpen((prev) => !prev);
    }, 5000);
  }

  return (
    <div className="bg-accent p-10 rounded-lg m-6 border border-accent">
      <h1 className="text-xl text-center">NEW INVASION ARTIST</h1>
      {formOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              {/* name */}
              <div className="form-control">
                <label className="label" htmlFor="name">
                  <span className="label-text text-xl">Artist Name</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="name"
                />
                <ErrorMessage name="name" component="div" className="error" />
              </div>

              {/* bio */}
              <div className="form-control">
                <label className="label" htmlFor="bio">
                  <span className="label-text text-xl">Bio</span>
                </label>
                <Field
                  className="input input-bordered"
                  as="textarea"
                  name="bio"
                />
                <ErrorMessage name="bio" component="div" className="error" />
              </div>
              {/* logo */}
              <div className="form-control">
                <label className="label" htmlFor="logo">
                  <span className="label-text text-xl">Logo</span>
                </label>
                <Field className="input input-bordered" name="logo" />
                <ErrorMessage name="logo" component="div" className="error" />
              </div>
              {/* IMAGE */}
              <div className="form-control">
                <label className="label" htmlFor="image">
                  <span className="label-text text-xl">Image URL</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="image"
                />
                <ErrorMessage name="image" component="div" className="error" />
              </div>
              {/* <FileUpload name="image" fileRef={fileRef} /> */}
              <div className="form-control mt-6">
                <button
                  className="btn btn-lg btn-secondary mx-auto"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {successOpen && (
        <p className="max-w-md my-6 text-lg">Blog Post Successfully Created</p>
      )}
    </div>
  );
}
