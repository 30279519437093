import React from "react";
import { useQuery } from "@apollo/client";
import { QUERY_FUTURE_EVENTS } from "../../utils/queries";
import Auth from "../../utils/auth";
import { useNavigate, Link } from "react-router-dom";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const adminLoggedIn = Auth.loggedIn();
  if (!adminLoggedIn) {
    navigate("/login");
  }

  const { loading, data, error } = useQuery(QUERY_FUTURE_EVENTS, {
    onCompleted: (data) => console.log("Query completed:", data),
    onError: (error) => console.error("Query error:", error),
  });

  if (loading) return <h2 className="text-white">...loading</h2>;
  if (error) return <h2 className="text-white">Error: {error.message}</h2>;

  if (!loading) {
    const events = data?.futureEvents || [];

    // Debugging: Log the events array
    console.log("Events data:", events);

    return (
      <div className="p-4 min-h-screen flex flex-col items-center">
        <Link to="/" onClick={() => Auth.logout()} className="text-white">
          LOGOUT
        </Link>
        <h1 className="text-white text-3xl mt-4">DASHBOARD</h1>
          <h2 className='text-xl text-center text-white'>Upcoming Events</h2>
        <div id="futureEventsContainer" className="flex flex-wrap mt-4 p-5">
          {events.length > 0 ? (
            events.map((event) => (
              <div className="border-y">
                <figure class="thumbnail">
                  <img
                    src={event.imgUrl}
                    alt={event.title} />
                </figure>
                <div className="card-body">
                  <h2 className="card-title">{event.title}</h2>
                  <p>{event.date}</p>
                  <div className="card-actions justify-end">
                    <button className="btn btn-primary" onClick={() => {
                                navigate(`/admin/event/${event._id}`);
                              }}>VIEW EVENT</button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-white">No upcoming events available</p>
          )}
        </div>
        <div id="pastEventsContainer">
        <h2 className='text-xl text-center text-white'>Past Events</h2>

        </div>
      </div>
    );
  }
};

export default AdminDashboard;
