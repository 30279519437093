import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './images/minimalist-logo-bonw.png';

const NavBar = () => {
  return (
    <div className='navbar bg-neutral text-black opacity-90 z-50 fixed top-0 left-0 right-0'>
      <div className='navbar-start'>
        <div className='dropdown'>
          <div tabIndex={0} role='button' className='btn btn-ghost lg:hidden'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h8m-8 6h16'
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className='menu menu-sm dropdown-content bg-neutral rounded-box mt-3 w-52 p-2 shadow'
          >
            <li>
              <Link to='/events'>EVENTS</Link>
            </li>
            <li>
              <Link to='/merch'>MERCH</Link>
            </li>
            <li>
              <Link to='/about'>ABOUT</Link>
            </li>
            <li>
              <Link to='/gallery'>GALLERY</Link>
            </li>
            <li>
              <Link to='/blog'>BLOG</Link>
            </li>
          </ul>
        </div>
        <Link to='/' className='btn btn-ghost text-xl'>
          <img src={Logo} className='h-12' alt='Logo' />
        </Link>
      </div>
      <div className='navbar-center hidden lg:flex'>
        <ul className='menu menu-horizontal px-1'>
          <li>
            <Link to='/events'>EVENTS</Link>
          </li>
          <li>
            <Link to='/merch'>MERCH</Link>
          </li>
          <li>
            <Link to='/about'>ABOUT</Link>
          </li>
          <li>
            <Link to='/gallery'>GALLERY</Link>
          </li>
          <li>
            <Link to='/blog'>BLOG</Link>
          </li>
        </ul>
      </div>
      <div className='navbar-end'>
        <div className='flex-none'>
          <ul className='menu menu-horizontal px-1'>
            <li>
              <div className='flex-none'>
                <div className='dropdown dropdown-end'>
                  <div
                    tabIndex={0}
                    role='button'
                    className='btn btn-ghost btn-circle'
                  >
                    <div className='indicator'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-5 w-5'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z'
                        />
                      </svg>
                      <span className='badge badge-sm indicator-item'>0</span>
                    </div>
                  </div>
                  <div
                    tabIndex={0}
                    className='mt-3 z-[1] card card-compact dropdown-content w-52 bg-neutral shadow'
                  >
                    <div className='card-body'>
                      <span className='font-bold text-lg'>0 Items</span>
                      <span className='text-info'>Subtotal: $0</span>
                      <div className='card-actions'>
                        <button className='btn btn-primary btn-block'>
                          View cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            {/* <li>
              <details>
                <summary className="btn btn-square btn-ghost">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="inline-block w-5 h-5 stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                    ></path>
                  </svg>
                </summary>
                <ul className="p-2 bg-neutral rounded-t-none">
                  <li>
                    <a>Link 1</a>
                  </li>
                  <li>
                    <a>Link 2</a>
                  </li>
                </ul>
              </details>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
