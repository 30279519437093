import React, { useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import Auth from '../../utils/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { QUERY_EVENT_BY_ID } from '../../utils/queries';
import AddDoorSaleForm from '../forms/AddDoorSaleForm';
import CreatePromoCodeForm from '../forms/CreatePromoCodeForm';

const EventTracker = () => {
  const { eventId } = useParams();
  const { loading, data, error } = useQuery(QUERY_EVENT_BY_ID, {
    variables: { eventId },
    onCompleted: (data) => console.log('Query completed:', data),
    onError: (error) => console.error('Query error:', error),
  });

  const navigate = useNavigate();
  const adminLoggedIn = Auth.loggedIn();
  if (!adminLoggedIn) {
    navigate('/login');
    return null; // Prevent further rendering
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const eventById = data?.eventById;

  // Create a map to count tickets per promo ID
  const promoTicketCount = {};
  eventById.ticketsSold.forEach((ticket) => {
    const promoId = ticket.promo?._id; // Assuming promo code is stored in the ticket
    if (promoId) {
      promoTicketCount[promoId] = (promoTicketCount[promoId] || 0) + 1;
    }
  });

  // Map promo IDs to their names and counts
  const promoCounts = eventById.promos.reduce((acc, promo) => {
    acc[promo._id] = {
      ...promo,
      count: promoTicketCount[promo._id] || 0,
    };
    return acc;
  }, {});

  // organize online tix
  let onlineTix = [...eventById.ticketsSold];
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  onlineTix.sort(dynamicSort('name'));
  return (
    <div className='min-h-screen flex items-top '>
      <div
        id='eventInfo'
        className='w-1/4 p-6 bg-accent min-h-screen text-center'
      >
        <img src={eventById.imgUrl} className='mb-5'></img>
        <h5 className='text-md'>Event</h5>
        <p className='text-lg font-bold'>{eventById.title}</p>
        <h5 className='text-md'>Date of Event</h5>
        <p className='text-lg font-bold'>{eventById.date}</p>
        <h5 className='text-md'>Attendance</h5>
        <p>135</p>
        <h5 className='text-md'>Total Tickets Sold</h5>
        <div>
          <h6 className='text-sm'>Online</h6>
          <h6 className='text-sm'>Door</h6>
        </div>
      </div>
      <div className='w-1/4'>
        <h3 className='text-xl font-bold mt-4 text-center'>Promos</h3>
        <div className='w-full flex flex-col max-h-screen overflow-y-scroll'>
          {eventById.promos.map((promo) => (
            <div
              key={promo._id}
              className='p-4 mb-2 bg-gray-100 rounded shadow text-black flex justify-between items-baseline'
            >
              <div>
                <h4 className='text-lg font-medium'>{promo.code}</h4>
                <p>Tickets Sold: {promoCounts[promo._id]?.count || 0}</p>
                <p>Remaining: {promo.quantity}</p>
              </div>
              <div className='text-xs'>
                <p className='text-right my-1 hover:underline hover:cursor-pointer'>
                  UPDATE
                </p>
                <p className='text-right my-1 hover:underline hover:cursor-pointer'>
                  DELETE
                </p>
              </div>
            </div>
          ))}
        </div>
        <CreatePromoCodeForm eventId={eventById._id} />
      </div>
      <AddDoorSaleForm eventId={eventById._id} />
      <div className='flex flex-col max-h-screen overflow-y-scroll'>
        {onlineTix.map((t) => (
          <p>{t.name}</p>
        ))}
      </div>
    </div>
  );
};

export default EventTracker;
