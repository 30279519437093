import React from "react";
import background from "../images/home-bg.jpg";

const SelectDelivery = ({
  event,
  selectedDeliveryMethod,
  setSelectedDeliveryMethod,
}) => {
  if (!event) {
    return <p>No event data available.</p>;
  }
  

  return (
    <div>
      <div className="text-black flex justify-center align-middle bg-white opacity-90 py-4 shadow-lg">
        <h2 className="my-2 text-center text-xl md:text-2xl lg:text-3xl">
          Select Delivery Method
        </h2>
      </div>
      <div className="p-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {event.ticketDeliveryOptions &&
        event.ticketDeliveryOptions.length > 0 ? (
          event.ticketDeliveryOptions.map((o, index) => (
            <div
              key={index}
              onClick={() => setSelectedDeliveryMethod(index)}
              className={`relative text-white bg-gradient-to-r from-blue-900 to-teal-900 opacity-90 border border-warning rounded-3xl p-5 shadow-2xl transform transition-transform hover:scale-105 ${
                selectedDeliveryMethod === o.method
                  ? "border-4 border-yellow-500"
                  : ""
              }`}
            >
              <div>
                <div className="flex items-center">
                  <h2 className="italic text-xl">
                    {"Option " + (index + 1)}
                    {o.method == "mobile" ? "(Free)" : "($" + o.price + ")"}
                  </h2>
                  {o.recommended ? (
                    <p className="text-sm ml-3">- RECOMMENDED</p>
                  ) : (
                    <></>
                  )}
                </div>
                <h3 className="text-3xl font-bold mt-5">
                  {o.method.toUpperCase()}
                </h3>
                <h4 className="text-lg">{o.description}</h4>
              </div>
              <div className="absolute top-4 right-4">
                <input
                  type="radio"
                  name="deliveryMethod"
                  value={o.method}
                  checked={selectedDeliveryMethod === index}
                  onChange={() => setSelectedDeliveryMethod(index)}
                  className="h-6 w-6 text-yellow-500 focus:ring-yellow-500 border-gray-300 rounded-full"
                />
              </div>
            </div>
          ))
        ) : (
          <p>
            No delivery options available. Show up and have your ID ready. We
            will have a list of names who pre-purchased tickets.
          </p>
        )}
      </div>
    </div>
  );
};

export default SelectDelivery;
