import React from "react";
import { Link } from "react-router-dom";

const InvasionNav = () => {
  return (
    <div
      id="InvasionNav"
      className="w-full flex text-md md:text-lg lg:text-2xl"
      style={{
        background: "linear-gradient(180deg, #000428, #004e92)",
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.6)",
      }}
    >
      <Link
        to="/Invasion/lineup"
        className="w-1/3 text-center text-white py-4 transition duration-300 ease-in-out transform hover:scale-105"
        style={{
          background: "linear-gradient(180deg, #f3ec78, #af4261)",
          boxShadow: "0 4px 8px 0 rgba(255, 215, 0, 0.8)",
          textShadow: "0px 0px 5px rgba(255, 215, 0, 0.8)",
        }}
      >
        LINEUP
      </Link>
      <Link
        to="/Invasion/getInvolved"
        className="w-1/3 text-center text-white py-4 transition duration-300 ease-in-out transform hover:scale-105"
        style={{
          background: "linear-gradient(180deg, #24c6dc, #514a9d)",
          boxShadow: "0 4px 8px 0 rgba(24, 202, 230, 0.8)",
          textShadow: "0px 0px 5px rgba(24, 202, 230, 0.8)",
        }}
      >
        GET INVOLVED
      </Link>
      <Link
        to="/Invasion/information"
        className="w-1/3 text-center text-white py-4 transition duration-300 ease-in-out transform hover:scale-105"
        style={{
          background: "linear-gradient(180deg, #2c3e50, #3498db)",
          boxShadow: "0 4px 8px 0 rgba(52, 152, 219, 0.8)",
          textShadow: "0px 0px 5px rgba(52, 152, 219, 0.8)",
        }}
      >
        INFORMATION
      </Link>
    </div>
  );
};

export default InvasionNav;
