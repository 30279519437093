import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import invasion from "../images/invasion_poster.jpg";
import InvasionNav from "./InvasionNav";
import { GET_INVASION_ARTISTS } from "../../utils/queries";

const InvasionLineup = () => {
  const { loading, data, error } = useQuery(GET_INVASION_ARTISTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading lineup</p>;

  const artists = data?.getInvasionArtists || [];

  return (
    <>
      <div>
        <InvasionNav />
        <div
          style={{
            background: "linear-gradient(135deg, #24c6dc 0%, #514a9d 100%)",
          }}
          className="flex flex-col align-center min-h-screen"
        >
          <img src={invasion} alt="Invasion" className="mb-10 mx-auto w-full" />
          <div className="flex justify-center m-5">
            <Link
              to="/events/669c6efadcf02ccf2ec35344"
              className="btn btn-xl text-white opacity-90 hover:opacity-100 py-4 px-6 transition duration-300 ease-in-out transform hover:scale-105"
              style={{
                background: "linear-gradient(180deg, #f3ec78, #af4261)",
                boxShadow: "0 4px 8px 0 rgba(255, 215, 0, 0.8)",
                textShadow: "0px 0px 5px rgba(255, 215, 0, 0.8)",
              }}
            >
              BUY TICKETS
            </Link>
          </div>
          <div className="text-center my-10">
            <h1 className="text-4xl text-white font-bold mb-10">Lineup</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {artists.map((artist, index) => (
                <div
                  key={index}
                  className="bg-white bg-opacity-10 rounded-lg p-5 transform hover:scale-105 transition duration-300 ease-in-out border-8 border-warning"
                >
                  <div className="bg-error rounded rounded-full border border-warning  h-[300px] text-justify">
                    <img
                      src={artist.image}
                      alt={artist.name}
                      className="w-full h-[300px] object-cover rounded-full border border-warning"
                    />
                  </div>
                  {/* <div className="flex justify-center items-center mb-4">
                    <img
                      src={artist.logo}
                      alt={`${artist.name} logo`}
                      className="w-30 h-30 object-contain"
                    />
                  </div> */}
                  <h2 className="text-xl text-warning font-semibold mb-2">
                    {artist.name}
                  </h2>
                  <p className="text-white">{artist.bio}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvasionLineup;
