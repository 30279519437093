import React from "react";
import { Link } from "react-router-dom";
import PPB from "./images/PPB.jpg";
//import { useQuery } from '@apollo/client';

const About = () => {
  return (
    <>
      <h1 className="text-2xl text-center my-6">OUR CO-FOUNDERS</h1>
      <div className="flex flex-col">
        <div id="LazAndZorb">
          <div className="flex justify-center">
            <img
              src={PPB}
              className="w-full md:w-1/2 lg:w-1/3 rounded rounded-full"
            ></img>
          </div>
          <p className="p-6">
            Pew Pew Productionz LLC was founded in 2023 by the legendary Supreme
            Air Marshall Lazarus Light, and Zorb Zal. After both of their
            planets were destroyed by the evil force, "The Riddim", they set out
            to defend the galaxy against this force. They do so by spreading
            peace and love, as well as their sound and frequencies. The Riddim
            cannot withstand music with a melody.
          </p>{" "}
          <p className="p-6">
            Laz and Zorb love to visit Earth frequently, so in order to keep the
            planet safe, they started Pew Pew Productionz to grace the world
            with only the best and most talented artists through live
            performance. The Pew Pew website doubles as a ticket sales platform
            intended to reduce the astronomical fees presented by most Earth
            ticketing platforms. Contact us you are interested in utilizing our
            website as your ticket platform.{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
