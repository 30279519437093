import React, { useState, useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useMutation } from '@apollo/client';
import { ADD_DOOR_SALE } from '../../utils/mutations';
import Auth from '../../utils/auth.js';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

export default function AddDoorSaleForm({ eventId }) {
  const navigate = useNavigate();
  const [addDoorSale] = useMutation(ADD_DOOR_SALE);

  const initialValues = {
    amount: '',
    method: '',
    quantity: '',
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required('This field is required'),
    quantity: Yup.number().required('This field is required'),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const { data } = await addDoorSale({
        variables: {
          amount: values.amount,
          method: values.method,
          quantity: values.quantity,
          eventId: eventId,
        },
      });
      resetForm();
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className='bg-accent rounded-lg p-5 border border-accent'>
      <h1 className='text-lg text-center text-white'>Add Door Sale</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            {/* METHOD */}
            <div className='form-control'>
              <label className='label' htmlFor='method'>
                <span className='label-text text-md'>Payment Method</span>
              </label>
              <select name='method'>
                <option value='venmo'>Venmo</option>
                <opttion value='cashapp'>Cashapp</opttion>
                <option value='cash'>Cash</option>
                <option value='card'>Card</option>
              </select>
              <ErrorMessage
                name='method'
                component='div'
                className='error text-white'
              />
            </div>
            {/* AMOUNT */}
            <div className='form-control'>
              <label className='label' htmlFor='amount'>
                <span className='label-text text-md'>Purchase Amount</span>
              </label>
              <Field
                className='input input-bordered text-white'
                type='number'
                name='amount'
              />
              <ErrorMessage
                name='amount'
                component='div'
                className='error text-white'
              />
            </div>
            {/* QUANTITY */}
            <div className='form-control'>
              <label className='label' htmlFor='quantity'>
                <span className='label-text text-md'>Quantity</span>
              </label>
              <Field
                className='input input-bordered text-white'
                type='number'
                name='quantity'
              />
              <ErrorMessage
                name='quantity'
                component='div'
                className='error text-white'
              />
            </div>

            <div className='form-control mt-6'>
              <button
                className='btn btn-lg btn-warning mx-auto'
                type='submit'
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
