import React from "react";
import { Link } from "react-router-dom";
import background from "./images/home-bg.jpg";
//import { useQuery } from '@apollo/client';

const Merch = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${background})` }}
        className="h-screen"
      >
        <div className="text-black flex flex-col justify-center items-center align-middle">
          <div className="bg-white opacity-90 my-5 h-16 w-1/3 text-center text-3xl flex flex-col justify-center">
            <h2>Merch</h2>
          </div>
        </div>
        <h1 className="text-3xl text-white mt-10 text-center">
          MERCH STORE COMING SOON
        </h1>
        {/* <img src={background}></img> */}
      </div>
    </>
  );
};

export default Merch;
