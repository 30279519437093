import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { QUERY_MY_TICKETS } from "../utils/queries";
import background from "./images/home-bg.jpg";
import Modal from "react-modal";
import { useSwipeable } from "react-swipeable";
import { format } from "date-fns";
import QRCode from "react-qr-code";

Modal.setAppElement("#root");

const Tickets = () => {
  const { paymentConfirmation, email } = useParams();
  const { loading, data, error } = useQuery(QUERY_MY_TICKETS, {
    variables: { paymentConfirmation, email },
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentTicketIndex, setCurrentTicketIndex] = useState(0);

  const openModal = (event) => {
    setCurrentEvent(event);
    setModalIsOpen(true);
    setCurrentTicketIndex(0);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentEvent(null);
  };

  const handleNext = () => {
    setCurrentTicketIndex((prevIndex) =>
      prevIndex + 1 === currentEvent.tickets.length ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentTicketIndex((prevIndex) =>
      prevIndex === 0 ? currentEvent.tickets.length - 1 : prevIndex - 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error! {error.message}</div>;

  const tickets = data?.myTickets || [];

  const groupedTickets = tickets.reduce((acc, ticket) => {
    const eventId = ticket.event._id;
    if (!acc[eventId]) {
      acc[eventId] = {
        event: ticket.event,
        tickets: [],
      };
    }
    acc[eventId].tickets.push(ticket);
    return acc;
  }, {});

  return (
    <div style={{ backgroundImage: `url(${background})` }} className="">
      <div className="text-black flex flex-col justify-center items-center align-middle">
        <div className="bg-white opacity-90 my-5 h-16 w-1/3 text-center text-3xl flex flex-col justify-center">
          <h1>Your Tickets</h1>
        </div>
      </div>
      <div className="p-4 flex flex-col md: flex-row lg:flex-row justify-evenly items-center">
        {Object.values(groupedTickets).map((group) => (
          <div
            key={group.event._id}
            className="mb-8 bg-white p-4 rounded-lg shadow-md cursor-pointer max-w-xs mx-auto lg:max-w-md"
            onClick={() => openModal(group)}
          >
            <h2 className="text-2xl text-black mb-4">{group.event.title}</h2>
            <img
              src={group.event.imgUrl}
              alt={group.event.title}
              className="w-full rounded-lg mb-4"
            />
          </div>
        ))}
      </div>
      {currentEvent && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="flex justify-center items-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center shadow-lg rounded-3xl"
        >
          <div
            {...handlers}
            className="bg-black py-4 px-10 rounded-3xl max-w-lg mx-auto flex flex-col  border border-yellow-500"
          >
            <button onClick={closeModal} className="self-end text-white mb-4">
              Close
            </button>
            <h2 className="text-2xl text-white mb-4">
              {currentEvent.event.title}
            </h2>
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: "50%",
                width: "100%",
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={currentEvent.tickets[currentTicketIndex].barcode}
                viewBox={`0 0 256 256`}
              />
            </div>
            {/* <ReactBarcode
              value={currentEvent.tickets[currentTicketIndex].barcode}
            /> */}
            <p className="mt-2 text-white text-center">
              (Ticket {currentTicketIndex + 1} of {currentEvent.tickets.length})
            </p>
            <p className="text-white">
              Ticket ID: {currentEvent.tickets[currentTicketIndex]._id}
            </p>
            <p className="text-white">
              Name: {currentEvent.tickets[currentTicketIndex].name}
            </p>
            <p className="text-white">
              Email: {currentEvent.tickets[currentTicketIndex].email}
            </p>
            <p className="text-white">
              Entered:{" "}
              {currentEvent.tickets[currentTicketIndex].entered ? "Yes" : "No"}
            </p>
            <p className="text-white">
              Payment Confirmation:{" "}
              {currentEvent.tickets[currentTicketIndex].paymentConfirmation}
            </p>
            <p className="text-white mb-4">
              Event Date: {format(new Date(currentEvent.event.date), "PPP")}
            </p>
            {currentEvent.tickets.length > 1 && (
              <div className="flex justify-between w-full ">
                <button
                  onClick={handlePrev}
                  className="text-white bg-red-500 px-4 py-2 rounded hover:bg-red-700 transition-all"
                >
                  Previous
                </button>
                <button
                  onClick={handleNext}
                  className="text-white bg-red-500 px-4 py-2 rounded hover:bg-red-700 transition-all"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Tickets;
