import React from "react";
import { Link } from "react-router-dom";
import invasion from "../images/invasion_poster_cropcrop.jpg";
import InvasionNav from "./InvasionNav";

const InvasionInformation = () => {
  return (
    <>
      <div>
        <InvasionNav />
        <div
          style={{
            background: "linear-gradient(135deg, #24c6dc 0%, #514a9d 100%)",
          }}
          className="flex flex-col align-center min-h-screen"
        >
          <img src={invasion} alt="Invasion" className="mb-10 mx-auto w-full" />
          <div className="flex justify-center m-5">
            <Link
              to="/events/669c6efadcf02ccf2ec35344"
              className="btn btn-xl text-white opacity-90 hover:opacity-100 py-4 px-6 transition duration-300 ease-in-out transform hover:scale-105"
              style={{
                background: "linear-gradient(180deg, #f3ec78, #af4261)",
                boxShadow: "0 4px 8px 0 rgba(255, 215, 0, 0.8)",
                textShadow: "0px 0px 5px rgba(255, 215, 0, 0.8)",
              }}
            >
              BUY TICKETS
            </Link>
          </div>
          <div className="max-w-4xl mx-auto p-6 bg-opacity-60 text-white my-5">
            <h1 className="text-4xl font-bold mb-4 text-center text-warning">
              Music Festival Information
            </h1>

            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-2 text-warning">
                Event Details
              </h2>
              <p>
                <strong>Age:</strong> 18+
              </p>
              <p>
                <strong>Location:</strong> River in RiNo, 3759 Chestnut Pl,
                Denver, CO 80216
              </p>
              <p>
                <strong>Door Time:</strong> 4:00 PM
              </p>
              <p>
                <strong>Event Start:</strong> 4:00 PM
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-2 text-warning">
                Do Not Bring
              </h2>
              <ul className="list-disc list-inside">
                <li>Weapons of any kind</li>
                <li>Illegal substances</li>
                <li>Outside food or beverages</li>
                <li>Alcohol</li>
                <li>Glass containers</li>
                <li>Large bags or backpacks</li>
                <li>Fireworks</li>
                <li>Laser pointers</li>
                <li>Pets (service animals are allowed)</li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-2 text-warning">
                Bag Policy
              </h2>
              <p>
                All bags must be clear and security-safe. Approved bags include:
              </p>
              <ul className="list-disc list-inside">
                <li>
                  Clear plastic, vinyl, or PVC bags that do not exceed 12" x 6"
                  x 12"
                </li>
                <li>
                  One-gallon clear plastic freezer bags (Ziploc bag or similar)
                </li>
                <li>
                  Small clutch bags, approximately the size of a hand, with or
                  without a handle or strap, can be taken to the event with one
                  of the clear plastic bags.
                </li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-2 text-warning">
                What You Can Bring
              </h2>
              <ul className="list-disc list-inside">
                <li>Flashlights</li>
                <li>Walkie-talkies</li>
                <li>Totems (must be made of lightweight material)</li>
                <li>Clear water bottles (empty, to be filled at the event)</li>
                <li>Hydration packs (must be empty upon entry)</li>
                <li>Disposable cameras</li>
                <li>Handheld fans</li>
                <li>Personal misters (small size)</li>
                <li>Flow toys</li>
                <li>Bubbles</li>
                <li>Pineapples (yes, you read that right!)</li>
              </ul>
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-2 text-warning">
                Parking Information
              </h2>
              <p>
                No parking is provided at the event. Street parking is available
                around the venue.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvasionInformation;
