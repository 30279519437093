import React from "react";
import { Link } from "react-router-dom";
import invasion from "../images/invasion_poster_cropcrop.jpg";
import invasionBG from "../images/invasion_poster.jpg";
import InvasionNav from "./InvasionNav";

const InvasionGetInvolved = () => {
  return (
    <>
      <div>
        <InvasionNav />
        <div className="flex flex-col items-center pb-10 bg-error">
          <img src={invasion} alt="Invasion" className="mb-10 w-full" />
          <div className="flex flex-wrap justify-center gap-4 mb-10">
            <Link
              to="https://forms.gle/ZUZyFg4aVLpGHB4C8"
              className="btn btn-xl text-white opacity-90 hover:opacity-100 py-4 px-6 transition duration-300 ease-in-out transform hover:scale-105"
              style={{
                background: "linear-gradient(180deg, #FFD700, #FFAA00)",
                boxShadow: "0 4px 8px 0 rgba(255, 215, 0, 0.8)",
                textShadow: "0px 0px 5px rgba(255, 215, 0, 0.8)",
              }}
            >
              VENDOR APPLICATION
            </Link>
            <Link
              to="https://forms.gle/w3VXpsiiqMJYANpN9"
              className="btn btn-xl text-white opacity-90 hover:opacity-100 py-4 px-6 transition duration-300 ease-in-out transform hover:scale-105"
              style={{
                background: "linear-gradient(180deg, #24c6dc, #514a9d)",
                boxShadow: "0 4px 8px 0 rgba(24, 202, 230, 0.8)",
                textShadow: "0px 0px 5px rgba(24, 202, 230, 0.8)",
              }}
            >
              STREET TEAM
            </Link>
            <Link
              to="https://forms.gle/H9uVWeZBHpAZvUea6"
              className="btn btn-xl text-white opacity-90 hover:opacity-100 py-4 px-6 transition duration-300 ease-in-out transform hover:scale-105"
              style={{
                background: "linear-gradient(180deg, #7ed56f, #28b485)",
                boxShadow: "0 4px 8px 0 rgba(46, 204, 113, 0.8)",
                textShadow: "0px 0px 5px rgba(46, 204, 113, 0.8)",
              }}
            >
              ARTIST SUBMISSION
            </Link>
            <Link
              to="https://forms.gle/bK2yyj9sY3V7FVrt7"
              className="btn btn-xl text-white opacity-90 hover:opacity-100 py-4 px-6 transition duration-300 ease-in-out transform hover:scale-105"
              style={{
                background: "linear-gradient(180deg, #8e44ad, #9b59b6)",
                boxShadow: "0 4px 8px 0 rgba(155, 89, 182, 0.8)",
                textShadow: "0px 0px 5px rgba(155, 89, 182, 0.8)",
              }}
            >
              STAGEHAND APPLICATION
            </Link>
            <Link
              to="https://forms.gle/XrPppsH2o3382h4E7"
              className="btn btn-xl text-white opacity-90 hover:opacity-100 py-4 px-6 transition duration-300 ease-in-out transform hover:scale-105"
              style={{
                background: "linear-gradient(180deg, #2c3e50, #3498db)",
                boxShadow: "0 4px 8px 0 rgba(52, 152, 219, 0.8)",
                textShadow: "0px 0px 5px rgba(52, 152, 219, 0.8)",
              }}
            >
              VOLUNTEER
            </Link>
          </div>
          <div className="text-center text-white">
            <h3 className="text-2xl mb-2">
              Interested in Sponsoring the Festival?
            </h3>
            <p>
              Contact us at{" "}
              <a href="mailto:info@pewpewprodz.com" className="text-yellow-300">
                info@pewpewprodz.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvasionGetInvolved;
