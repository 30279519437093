import React, { useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import background from "../images/home-bg.jpg";
import { useMutation } from "@apollo/client";
import { SEND_TICKET_EMAIL } from "../../utils/mutations";

export default function PaymentSuccess() {
  const { paymentConfirmation } = useParams();
  const [sendTicketEmail] = useMutation(SEND_TICKET_EMAIL);
  const checkoutPage = 4;
  const pageNames = useMemo(
    () => [
      "Step 1|Select Tickets",
      "Step 2|Delivery",
      "Step 3|Pay",
      "Step 4|Confirmation",
    ],
    []
  );

  useEffect(() => {
    const send = () => {
      sendTicketEmail({
        variables: {
          paymentConfirmation,
        },
      })
        .then(() => {
          console.log("Email successfully sent");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    };
    send();
  }, [paymentConfirmation, sendTicketEmail]);

  //checkout steps tab
  const renderSteps = useMemo(() => {
    return (
      <div className="flex justify-start bg-black">
        <div id="checkoutSteps" className="flex flex-wrap">
          {pageNames.map((page, index) => {
            let pageClass = "text-white p-3";
            if (index + 1 < checkoutPage) {
              pageClass = "text-gray-500 p-3";
            }
            if (index === checkoutPage - 1) {
              pageClass = "text-warning p-3";
            }
            return (
              <p key={"page" + index} className={pageClass}>
                {page}
              </p>
            );
          })}
        </div>
      </div>
    );
  }, [checkoutPage, pageNames]);

  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className=" bg-cover bg-center relative flex flex-col items-center"
    >
      {renderSteps}
      <div className="bg-gray-900 my-24 p-4 w-5/6 rounded rounded-lg border border-yellow-500 shadow-lg">
        <h1 className="text-4xl text-center my-6">PAYMENT SUCCESSFUL</h1>
        <h3 className="text-center text-xl">
          You should receive an email shortly with instructions on how to access
          your tickets.
        </h3>
        <p className="text-center text-lg my-3">
          Your confirmation code is: <b> {paymentConfirmation}</b>
        </p>
        <p className="text-center text-sm mb-3">
          Please take a screenshot of this code for your records.
        </p>
      </div>
    </div>
  );
}
