import Html5QrcodePlugin from "./Html5QrcodePlugin";
import { CHECK_TICKETS } from "../../utils/mutations";
import React, { useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Auth from "../../utils/auth";

const ScanTicket = (props) => {
  const navigate = useNavigate();
  const adminLoggedIn = Auth.loggedIn();
  if (!adminLoggedIn) {
    navigate("/login");
  }
  const [checkTickets] = useMutation(CHECK_TICKETS);
  const [error, setError] = useState("");
  const [ticketData, setTicketData] = useState({
    _id: "",
    barcode: "",
    deliveryMethod: {
      method: "",
    },
    entered: "",
    email: "",
    event: {
      title: "",
    },
    name: "",
    paymentConfirmation: "",
  });
  const [isScanning, setIsScanning] = useState(true);
  const qrCodeRef = useRef(null);

  const onNewScanResult = async (decodedText, decodedResult) => {
    if (!isScanning) return;
    // Stop scanning for 3 seconds
    setIsScanning(false);
    if (qrCodeRef.current && qrCodeRef.current.pause) {
      qrCodeRef.current.pause();
    }
    setTimeout(() => {
      // Resume scanning
      if (qrCodeRef.current && qrCodeRef.current.resume) {
        qrCodeRef.current.resume();
      }
      // Refresh the page
      window.location.reload();
    }, 5000);

    console.log(decodedResult, decodedText);

    try {
      const { data, error } = await checkTickets({
        variables: { barcode: decodedText },
      });
      console.log(data);

      if (data) {
        setTicketData(data.checkTickets);
        setError(""); // Reset error
      }
      if (error) {
        setError(error.message || "An error occurred");
      }
    } catch (err) {
      console.error("Scan error:", err);
      setError("An unexpected error occurred");
    }
  };

  const getBackgroundColor = () => {
    return ticketData.entered === false ? "bg-green-500" : "bg-red-500"; // Green for unentered tickets, red for entered
  };

  return (
    <div
      className={`p-4 ${getBackgroundColor()} min-h-screen flex flex-col items-center justify-center`}
    >
      {isScanning && (
        <Html5QrcodePlugin
          ref={qrCodeRef}
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
          isScanning={isScanning}
        />
      )}
      {ticketData && (
        <div className="mt-4 p-4 bg-white text-black rounded shadow-md">
          <p>Barcode: {ticketData.barcode}</p>
          <p>Name: {ticketData.name}</p>
          <p>Entered: {ticketData.entered.toString()}</p>
          <p>Event: {ticketData.event.title}</p>
          <p>Payment Confirmation: {ticketData.paymentConfirmation}</p>
        </div>
      )}
      {error && <p className="text-red-500 mt-4">{error}</p>}
    </div>
  );
};

export default ScanTicket;
