import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_EVENT } from "../../utils/mutations.js";
import { QUERY_EVENT_BY_ID } from "../../utils/queries.js";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

export default function UpdateEventForm() {
  const [updateEvent] = useMutation(UPDATE_EVENT);
  const [successOpen, setSuccessOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(true);
  const { eventId } = useParams();
  const { loading, data, error } = useQuery(QUERY_EVENT_BY_ID, {
    variables: { eventId: eventId },
    onCompleted: (data) => console.log("Query completed:", data),
    onError: (error) => console.error("Query error:", error),
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading event data</p>;

  const eventData = data.eventById;
  const initialValues = {
    date: eventData.date || "",
    doorTime: eventData.doorTime || "",
    showTime: eventData.showTime || "",
    venue: eventData.venue || "",
    address: eventData.address || "",
    title: eventData.title || "",
    description: eventData.description || "",
    image: eventData.image || "",
    externalTicketLink: eventData.externalTicketLink || "",
    tiers: eventData.tiers || [],
    promos: eventData.promos || [],
    deliveryOptions: eventData.deliveryOptions || [],
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string(),
    venue: Yup.string(),
    address: Yup.string(),
    title: Yup.string(),
    description: Yup.string(),
    image: Yup.string(),
    externalTicketLink: Yup.string(),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const filteredTiers = values.tiers.filter(
        (tier) => tier.level !== "" || tier.price !== "" || tier.quantity !== ""
      );

      const processedTiers = filteredTiers.map((tier) => ({
        ...tier,
        price: Number(tier.price),
        quantity: Number(tier.quantity),
      }));

      const filteredPromos = values.promos.filter(
        (promo) => promo.code !== "" && promo.discount !== ""
      );

      const processedPromos = filteredPromos.map((promo) => ({
        ...promo,
        discount: Number(promo.discount),
        quantity: Number(promo.quantity),
      }));

      const filteredDeliveryOptions = values.deliveryOptions.filter(
        (o) => o.method !== "" && o.price !== ""
      );

      const processedDeliveryOptions = filteredDeliveryOptions.map(
        (option) => ({
          ...option,
          price: Number(option.price),
        })
      );

      const updateFields = {};
      for (const key in initialValues) {
        if (
          JSON.stringify(initialValues[key]) !== JSON.stringify(values[key])
        ) {
          updateFields[key] = values[key];
        }
      }

      if (updateFields.tiers) updateFields.tiers = processedTiers;
      if (updateFields.promos) updateFields.promos = processedPromos;
      if (updateFields.deliveryOptions)
        updateFields.deliveryOptions = processedDeliveryOptions;
      updateFields.eventId = eventData._id;
      console.log(updateFields);
      await updateEvent({
        variables: updateFields,
      });
      resetForm();
      showSuccessMessage();
    } catch (err) {
      console.error("Submission error:", err);
    }
    setSubmitting(false);
  };

  function showSuccessMessage() {
    setSuccessOpen((prev) => !prev);
    setFormOpen((prev) => !prev);
    setTimeout(() => {
      setSuccessOpen((prev) => !prev);
      setFormOpen((prev) => !prev);
    }, 5000);
  }

  return (
    <div className="bg-accent p-10 rounded-lg m-6 border border-accent">
      <h1 className="text-xl text-center">NEW EVENT</h1>
      {formOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <div className="form-control">
                <label className="label" htmlFor="title">
                  <span className="label-text text-xl">Event Title</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="title"
                />
                <ErrorMessage name="title" component="div" className="error" />
              </div>
              <div className="form-control">
                <label className="label" htmlFor="date">
                  <span className="label-text text-xl">Event Date</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="date"
                  name="date"
                />
                <ErrorMessage name="date" component="div" className="error" />
              </div>
              <div className="form-control">
                <label className="label" htmlFor="doorTime">
                  <span className="label-text text-xl">Door Time</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="doorTime"
                />
                <ErrorMessage
                  name="doorTime"
                  component="div"
                  className="error"
                />
              </div>
              <div className="form-control">
                <label className="label" htmlFor="showTime">
                  <span className="label-text text-xl">Show Time</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="showTime"
                />
                <ErrorMessage
                  name="showTime"
                  component="div"
                  className="error"
                />
              </div>
              <div className="form-control">
                <label className="label" htmlFor="venue">
                  <span className="label-text text-xl">Venue</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="venue"
                />
                <ErrorMessage name="venue" component="div" className="error" />
              </div>
              <div className="form-control">
                <label className="label" htmlFor="address">
                  <span className="label-text text-xl">Address</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="address"
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error"
                />
              </div>
              <div className="form-control">
                <label className="label" htmlFor="description">
                  <span className="label-text text-xl">Description</span>
                </label>
                <Field
                  className="input input-bordered"
                  as="textarea"
                  name="description"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>
              <h2 className="text-3xl text-center mt-3">Ticket Tiers</h2>
              <FieldArray name="tiers">
                {({ remove, push }) => (
                  <>
                    {values.tiers.map((tier, index) => (
                      <div key={index} className="flex justify-between">
                        <h3 className="text-xl">{index + 1}</h3>
                        <div className="form-control">
                          <label
                            className="label"
                            htmlFor={`tiers[${index}].level`}
                          >
                            <span className="label-text text-xl">Name</span>
                          </label>
                          <Field
                            className="input input-bordered"
                            type="text"
                            name={`tiers[${index}].level`}
                          />
                          <ErrorMessage
                            name={`tiers[${index}].level`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-control">
                          <label
                            className="label"
                            htmlFor={`tiers[${index}].price`}
                          >
                            <span className="label-text text-xl">Price</span>
                          </label>
                          <Field
                            className="input input-bordered"
                            type="number"
                            name={`tiers[${index}].price`}
                          />
                          <ErrorMessage
                            name={`tiers[${index}].price`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-control">
                          <label
                            className="label"
                            htmlFor={`tiers[${index}].quantity`}
                          >
                            <span className="label-text text-xl">Quantity</span>
                          </label>
                          <Field
                            className="input input-bordered"
                            type="number"
                            name={`tiers[${index}].quantity`}
                          />
                          <ErrorMessage
                            name={`tiers[${index}].quantity`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-control">
                          <button
                            type="button"
                            className="btn btn-error"
                            onClick={() => remove(index)}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        push({ level: "", price: "", quantity: "" })
                      }
                    >
                      Add Tier
                    </button>
                  </>
                )}
              </FieldArray>
              <h2 className="text-3xl text-center mt-3">Ticket Promos</h2>
              <FieldArray name="promos">
                {({ remove, push }) => (
                  <>
                    {values.promos.map((promo, index) => (
                      <div key={index} className="flex justify-between">
                        <h3 className="text-xl">{index + 1}</h3>
                        <div className="form-control">
                          <label
                            className="label"
                            htmlFor={`promos[${index}].code`}
                          >
                            <span className="label-text text-xl">Code</span>
                          </label>
                          <Field
                            className="input input-bordered"
                            type="text"
                            name={`promos[${index}].code`}
                          />
                          <ErrorMessage
                            name={`promos[${index}].code`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-control">
                          <label
                            className="label"
                            htmlFor={`promos[${index}].discount`}
                          >
                            <span className="label-text text-xl">Discount</span>
                          </label>
                          <Field
                            className="input input-bordered"
                            type="number"
                            name={`promos[${index}].discount`}
                          />
                          <ErrorMessage
                            name={`promos[${index}].discount`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-control">
                          <label
                            className="label"
                            htmlFor={`promos[${index}].quantity`}
                          >
                            <span className="label-text text-xl">Quantity</span>
                          </label>
                          <Field
                            className="input input-bordered"
                            type="number"
                            name={`promos[${index}].quantity`}
                          />
                          <ErrorMessage
                            name={`promos[${index}].quantity`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-control">
                          <button
                            type="button"
                            className="btn btn-error"
                            onClick={() => remove(index)}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        push({ code: "", discount: "", quantity: "" })
                      }
                    >
                      Add Promo
                    </button>
                  </>
                )}
              </FieldArray>
              <h2 className="text-3xl text-center mt-3">Delivery Options</h2>
              <FieldArray name="deliveryOptions">
                {({ remove, push }) => (
                  <>
                    {values.deliveryOptions.map((option, index) => (
                      <div key={index} className="flex justify-between">
                        <h3 className="text-xl">{index + 1}</h3>
                        <div className="form-control">
                          <label
                            className="label"
                            htmlFor={`deliveryOptions[${index}].method`}
                          >
                            <span className="label-text text-xl">Method</span>
                          </label>
                          <Field
                            className="input input-bordered"
                            type="text"
                            name={`deliveryOptions[${index}].method`}
                          />
                          <ErrorMessage
                            name={`deliveryOptions[${index}].method`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-control">
                          <label
                            className="label"
                            htmlFor={`deliveryOptions[${index}].price`}
                          >
                            <span className="label-text text-xl">Price</span>
                          </label>
                          <Field
                            className="input input-bordered"
                            type="number"
                            name={`deliveryOptions[${index}].price`}
                          />
                          <ErrorMessage
                            name={`deliveryOptions[${index}].price`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-control">
                          <button
                            type="button"
                            className="btn btn-error"
                            onClick={() => remove(index)}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => push({ method: "", price: "" })}
                    >
                      Add Option
                    </button>
                  </>
                )}
              </FieldArray>
              <div className="form-control">
                <label className="label" htmlFor="image">
                  <span className="label-text text-xl">Image</span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="image"
                />
                <ErrorMessage name="image" component="div" className="error" />
              </div>
              <div className="form-control">
                <label className="label" htmlFor="externalTicketLink">
                  <span className="label-text text-xl">
                    External Ticket Link
                  </span>
                </label>
                <Field
                  className="input input-bordered"
                  type="text"
                  name="externalTicketLink"
                />
                <ErrorMessage
                  name="externalTicketLink"
                  component="div"
                  className="error"
                />
              </div>
              <div className="form-control mt-6">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {successOpen && (
        <div>
          <p className="text-primary text-center">
            Form submission successful!
          </p>
        </div>
      )}
    </div>
  );
}
